import * as React from "react"
import GoogleMapReact from "google-map-react"
import Layout from "../components/layout"
const AnyReactComponent = ({ text }) => <div>{text}</div>

const IndexPage = () => (
  <Layout>
    <header
      id="up"
      class="bg-center bg-fixed bg-no-repeat bg-center bg-cover h-190 relative"
    >
      <div
        class="h-190 bg-opacity-50 bg-black flex items-center justify-center"
        style={{ background: "rgba(0,0,0,0.5)" }}
      >
        <section class=" text-gray-200 ">
          <div class="max-w-6xl mx-auto px-5 py-24 ">
            <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
              <h1 class=" title-font mb-2 text-3xl font-extrabold leading-10 tracking-tight text-center sm:text-5xl sm:leading-none md:text-7xl ">
                {" "}
                Our Sunday Service
              </h1>
              <h1 class=" title-font mb-2 text-3xl font-extrabold leading-10 tracking-tight text-center sm:text-5xl sm:leading-none md:text-6xl p-5  text-white">
                {" "}
                xx:00am-xx:00pm
              </h1>
              <p class="lg:w-1/2 w-full leading-relaxed text-base  text-2xl">
                FOR NOTHING IS IMPOSSIBLE WITH GOD
              </p>
            </div>
          </div>
        </section>
      </div>
    </header>

    <div class="px-6 py-2 ">
      <div class="container flex justify-between mx-auto">
        <div class="container overflow-x-hidden ">
          <div class="flex flex-wrap md items-center w-full">
            <div class="bg-white  xs:w-1/2 md:w-1/2 ">
              <div class="p-1">
                <h1 class="text-6xl font-bold mt-16 text-red-400 text-center">
                  Our location
                </h1>

                <div class="description w-full  md:w-100 mt-16 text-gray-500 text-2xl text-center">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum' will uncover many web sites still in their
                  infancy. Various versions have evolved over the years,
                  sometimes by accident, sometimes on purpose (injected humour
                  and the like).
                </div>
              </div>
            </div>
            <div class="  md:w-1/2  p-1 md:p-10">
              <GoogleMapReact
                bootstrapURLKeys={{ key: 2222222 }}
                defaultZoom={1}
              >
                <AnyReactComponent
                  lat={59.955413}
                  lng={30.337844}
                  text="My Marker"
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
